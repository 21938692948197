/* styles.css */
.navbar-white {
    color: white;
  }
  
  .navbar-white .nav-link {
    color: white;
    
  }
hr{
    display: none;
  }
  .btrjbLogo{
    width:220px; 
    height:100px; 
  }
  
  .navbar-white .nav-link.active {
    font-weight: bold; /* Make active link bold */
    color: rgb(239, 22, 181)!important;
    font-size: larger;
    transition: cubic-bezier(12s);
  }
  .navbar-collapse {
    justify-content: flex-end !important;
  }
  
  
  .navbar-brand {
    color: white !important;
    font-family: "Raleway", sans-serif;

  }
  .custom-hamburger-icon {
    color: white; /* Adjust the color as needed */
    font-size: 24px;
}
@media (max-width:412px) {
  .btrjbLogo{
    width: 180px;
    height: 70px;
    margin-bottom: -20px;
  }
  
  .navbar-collapse {
    text-align:center;
    justify-content:end !important;
    background-color:rgb(5 6 45);
    backdrop-filter: blur(123px);
    border-radius: 15px;
    margin-top: 12px ;
    
  }
  hr{
    display: block;
  }
  
}
