.career-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's on top of other elements */
  overflow-y: auto; /* Allow scrolling if content overflows */
}
div#ff-footer {
  display: none !important;
}
.rest-btn rest-btn-lg rest-btn-primary create-your-own-form{
  display: none;
}
.career-popup {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  max-height: 90vh; /* Ensures it doesn't overflow the screen vertically */
  overflow-y: auto; /* Scroll inside the popup if the content is too long */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

button {
  margin: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .career-popup {
    max-width: 90%; /* Make the popup smaller on mobile screens */
    max-height: 80vh; /* Further reduce the height on mobile */
    padding: 10px; /* Reduce padding for smaller screens */
  }
}
