.abt_title{
    font-size:4em;
    --tw-text-opacity: 1;
    color:rgb(255 255 255 / var(--tw-text-opacity));
    font-weight: 300;
    text-align: end;
    margin-top:40px ;
}
.crystal{
    width: 70%;
    height: auto;
    margin-top: -108px;
    margin-left: -141px;
}
.abt__content{
    color: white;
    font-size: 1.2em;
    font-weight: 100;
    text-align: end;
    text-align: justify;
    opacity: 1;
    transition: 2s;
}
.abt__content::after{
    opacity: 100;
}
@media (max-width:412px) {
    .abt_title{
        font-size:3em;
        --tw-text-opacity: 1;
        color:rgb(255 255 255 / var(--tw-text-opacity));
        font-weight: 300;
        text-align: end;
        margin-top:40px ;
    }
    .crystal{
        display: none;
        width: 50%;
        height: auto;
        margin-top: -108px;
        margin-left: -200px;
        opacity: 90%;
    }
    .abt__content{
        color: white;
        font-size: 1em;
        font-weight: 100;
        text-align: end;
        text-align: justify;
        opacity: 1;
        transition: 2s;
    }
    .abt__content::after{
        opacity: 100;
    }    
}