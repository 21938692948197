.tpanim{
    font-size:2em;
    font-weight: 300;
    --tw-text-opacity: 1;
    color:rgb(255 255 255 / var(--tw-text-opacity))
}
@media (max-width:412px) {
    .tpanim {
        font-size: 1.5em;
        font-weight: 300;
        --tw-text-opacity: 1;
        color:rgb(255 255 255 / var(--tw-text-opacity))
    }
}