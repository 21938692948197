.cont_title{
    font-size:4em;
    --tw-text-opacity: 1;
    color:rgb(255 255 255 / var(--tw-text-opacity));
    font-weight: 300;
    text-align: start;
    margin-top:40px ;
}
.cont_card{
    max-width: 100%;
    margin-top: 20px;
    height: 600px;
    background-color: rgba(205, 75, 238, 0.055);
    backdrop-filter: blur(123px);
    border-radius: 50px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.con_img{
    margin-top: 100px;
    margin-left: 60px;
    width:70%;
    height:auto;
}
.vertical-line{
    border-left: 2px solid #ffffff38; /* Adjust the color and width as needed */
    height: 500px;
    margin-top: 60px;
}
.list{
    list-style: none;    
    color: #ffff;


}
.getintouch{
    display: flex;
    flex-direction: column;
    gap:20px;
    margin-right:20px;
}
.getintouch input,textarea{
    padding: 12px;
    border-radius: 12px;
    background-color: transparent;
    backdrop-filter: blur(18px);
    border: none;
    color: #ffff;
    
}
input::placeholder{
    color: rgba(255, 255, 255, 0.719)!important;
    font:1em sans-serif;
    font-weight: 200;
    
    
}
textarea::placeholder{
    color: rgba(255, 255, 255, 0.664);
    font: 1em sans-serif;
}
@media (max-width:412px){
    
    .cont_title{
        font-size:2em;
        --tw-text-opacity: 1;
        color:rgb(255 255 255 / var(--tw-text-opacity));
        font-weight: 300;
        text-align: center;
        margin-top:40px ;
    }
    .cont_card{
        padding-left: 2px;
        padding-right: 2px;
        position: relative;
        max-width: 100%;
        /* margin-top: 20px; */
        height: 500px;
        background-color: rgba(205, 75, 238, 0.055);
        backdrop-filter: blur(123px);
        border-radius: 30px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    }
    
    .con_img{
        position: absolute;
        left: 20%;
        margin-top: 100px;
        margin-left: 0px;
        width:70%;
        height:auto;
        opacity: 80%;
    }
    .vertical-line{
        border-left: 2px solid #ffffff38; /* Adjust the color and width as needed */
        height: 500px;
        margin-top: 60px;
        display: none;
    }
    .list{
        list-style: none;    
        color: #ffff;
    
    
    }
    .getintouch{
        
        display: flex;
        flex-direction: column;
        gap:10px;
        margin-right:0px;
        width: 100%;
    }
    .getintouch input,textarea{
        padding: 6px 6px 6px 6px;
        border-radius: 0px;
        background-color: rgba(5, 6, 45, 0.342);
        backdrop-filter: blur(28px);
        border: none;
        color: #ffff;
        padding-left: 2px;
        padding-right: 2px;
        
    }
    input::placeholder{
        color: rgba(255, 255, 255, 0.719)!important;
        backdrop-filter: blur(120px);
        font:1em sans-serif;
        font-weight: 200;
        
        
    }
    textarea::placeholder{
        color: rgba(255, 255, 255, 0.664);
        font: 1em sans-serif;
    }   
}