@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Nanum+Gothic&family=Outfit:wght@100..900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Nanum+Gothic&family=Outfit:wght@100..900&family=Rajdhani:wght@300;400;500;600;700&family=Raleway:ital,wght@0,100..900;1,100..900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
body {
  background: rgb(44,30,74);
  background: linear-gradient(180deg, rgba(44,30,74,1) 0%, rgba(44,30,74,1) 100%);
  backdrop-filter: blur(21px);
  color: white; /* Optional: Change text color to white for better contrast */
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  box-sizing: border-box;

}
h1{
  color: white;
}
h2{
  color: #fff;
}
/* Hide the "Create your own form" button and watermark text */
.ff-watermark {
  display: none !important;
}
