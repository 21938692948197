.form-group input::placeholder,
.form-group select::placeholder,
.form-group1 input::placeholder {
  color: #4b5763!important;
  opacity: 1!important;  
  font-size: 1em!important;
  padding-left: 8px;
}

.form-group input,
.form-group select,
.form-group1 input {
  padding: 10px;  
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1.7em;
  color: #495057;  
}
label{
  font-size: 0.8em;
  margin-top: 1px;
  display:none;
  color: #ffffffb0!important;
}

.career-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Use viewport height to ensure it covers the full screen */
  background: rgba(0, 0, 0, 0.911);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto; /* Allow scrolling if content exceeds screen height */
}

.career-popup {
  background: rgb(255, 255, 255);
  padding: 15px;
  border-radius: 15px;
  max-width: 500px;
  border-color: 3px #6200b3;
  width: 100%;
  
  /* Animation */
  opacity: 0;
  transform: scale(0.8);
  animation: popup-animation 0.3s ease-in-out forwards;
}

@keyframes popup-animation {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


.career-popup h2 {
  text-align: center;
  margin-top: 0;
  text-transform: capitalize;
   font-size: 1.3em!important;
    --tw-text-opacity: 1;
    color:rgb(112, 3, 134);
    text-shadow: var(--tw-text-opacity);
    background-color: #ffffff!important;
    padding: 12px;
    margin-top: -14px;
    margin-left: -14px;
    margin-right: -14px;
    border-radius: 15px 15px 0px 0px;
}

.form-group, .form-group1 {
  /* margin-bottom: 0px; */
  width: 100%;
}

.form-group input,
.form-group select,
.form-group1 input {
  width: 100%;
  padding: 8px;
  border-radius: 12px;
  font-size: 0.8em;
  background-color: rgb(236, 229, 245);

}
.form-group input,select:focus{
 outline: none;

}
.submit-btn {
  background: #9900ff;
  color: rgba(255, 255, 255, 0.952);
  border: none;
  padding: 4px 12px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.submit-btn:hover {
  background: #6200b3;
}

/* .upload {
  display: block;
} */
 .form_label{
  font-size: 0.7em;
  color: #495057!important;
  margin-bottom: 2px;
  margin-left: 2px;
 }
