.deco{
    width: 100%;
    height: auto;
    margin-top: -120px;
}
.contianer{
    padding: 50px;
    position: relative;

}
/* Hide the "Create your own form" button */
.ff-watermark {
    display: none !important;
  }
  
.col-md-7{
    margin-top:122px;
}
.col-md-7 p{
    font-size: 2em;
    font-weight: 300;
    --tw-text-opacity: 1;
    color:rgb(255 255 255 / var(--tw-text-opacity))
}
.col-md-7 h1{
    font-size: 3em;
    font-weight: 300;
    --tw-text-opacity: 1;
    color:rgb(255 255 255 / var(--tw-text-opacity))
}
.spacer{
    height: 200px;
}
@media (max-width:412px) {
    .contianer{
        padding: 0px;
        position: relative;
    
    }
    .col-md-7{
        margin-top:20px;
    }
    .col-md-7 p{
        font-size: 1.7em;
        font-weight: 300;
        --tw-text-opacity: 1;
        color:rgb(255 255 255 / var(--tw-text-opacity));
        z-index: 99;
    }
    .col-md-7 h1{
        font-size: 2.8em;
        font-weight: 300;
        text-align: justify;
        justify-content: center;
        --tw-text-opacity: 1;
        color:rgb(255 255 255 / var(--tw-text-opacity));
        z-index: 99;
        
    }
    .deco{
        display: none;  
        width: 70%;
        height: auto;
        margin-top: -40px!important;
        margin-left: 35px;
        z-index: -1;
        opacity: 50%;
        position: absolute;

    }
    
    
    
}

